form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  label {
    font-size: 20px;
    margin-top: 10px;
  }
  
  input {
    height: 30px;
    padding: 5px;
    font-size: 18px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button[type="submit"] {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #3e8e41;
  }
  

  h1,p{
    text-align: center;
  
  }

  table {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
  }
  
  td, th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
  }
  