nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f7f7f7;
    padding: 1rem;
    font-size: 1.2rem;
  }
  
  .logo {
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  ul {
    display: flex;
    list-style: none;
  }
  
  li {
    margin-right: 1rem;
  }
  
  a {
    color: #333;
    text-decoration: none;
  }
  